/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-24 20:42:19
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询用户信息列表
export function listUsers(query) {
  return request({
    url: '/internal/recyclable/user/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询用户信息详细
export function getUsers(id) {
  return request({
    url: '/internal/recyclable/user/' + (id || ''),
    baseURL,
    method: 'get'
  })
}

// 通过账号模糊搜索驿站员工信息
export function listWorkers(query) {
  return request({
    url: '/internal/recyclable/user/listWorkers',
    baseURL,
    method: 'get',
    params: query
  })
}

// 新增用户信息
export function addUsers(data) {
  return request({
    url: '/internal/recyclable/user',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUsers(data) {
  return request({
    url: '/internal/recyclable/user',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除用户信息
export function delUsers(id) {
  return request({
    url: '/internal/recyclable/user/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出用户信息
export function exportUsers(query) {
  return request({
    url: '/internal/recyclable/user/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 重置密码
export function resetUserPwd(data) {
  return request({
    url: '/internal/recyclable/user/resetPwd',
    baseURL,
    method: 'put',
    data: data
  })
}
