/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-28 14:15:56
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询提现记录列表
export function listWithdrawalRecord(query) {
  return request({
    url: '/internal/recyclable/withdrawalRecord/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询提现记录详细
export function getWithdrawalRecord(id) {
  return request({
    url: '/internal/recyclable/withdrawalRecord/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增提现记录
export function addWithdrawalRecord(data) {
  return request({
    url: '/internal/recyclable/withdrawalRecord',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改提现记录
export function updateWithdrawalRecord(data) {
  return request({
    url: '/internal/recyclable/withdrawalRecord',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除提现记录
export function delWithdrawalRecord(id) {
  return request({
    url: '/internal/recyclable/withdrawalRecord/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出提现记录
export function exportWithdrawalRecord(query) {
  return request({
    url: '/internal/recyclable/withdrawalRecord/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 批量确认提现已转账
export function updateWithdrawalStatus(ids) {
  return request({
    url: '/internal/recyclable/withdrawalRecord/updateWithdrawalStatus/' + ids,
    baseURL,
    method: 'put'
  })
}

// 通过银行卡号查询信息
export function searchBank(params) {
  return request({
    url: '/internal/recyclable/bankInfo/searchBank',
    baseURL,
    method: 'get',
    params
  })
}
