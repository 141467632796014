<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户账号" prop="userId">
                <!-- <a-input v-model="queryParam.userId" placeholder="请输入所属用户id" allow-clear /> -->
                <a-select
                  show-search
                  :disabled="onlyRead"
                  allowClear
                  placeholder="请输入用户账号模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleUserNameSearch"
                  @change="handleUserNameChange"
                  :loading="loading"
                  v-model="queryParam.userId"
                >
                  <a-select-option v-for="d in usersList" :key="d.userId" :value="d.userId">
                    {{ d.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="类别" prop="type">
                <a-select placeholder="请选择类别" v-model="queryParam.type" style="width: 100%" allow-clear>
                  <a-select-option v-for="(item, index) in typeOptions" :key="index" :value="item.dictValue">{{
                    item.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="账单流水号" prop="accountNo">
                  <a-input
                    v-model="queryParam.accountNo"
                    placeholder="请输入账单流水号 类别是收入时记录回收账单流水号，类别是提现时记录提现流水号"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="发生金额" prop="amount">
                  <a-input
                    v-model="queryParam.amount"
                    placeholder="请输入发生金额 收入所得时是正值，提现时是负值"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="发生金额后的余额" prop="balance">
                  <a-input v-model="queryParam.balance" placeholder="请输入发生金额后的余额" allow-clear />
                </a-form-item>
              </a-col> -->
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="是否删除" prop="status">
                  <a-select placeholder="请选择是否删除" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:userAccount:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:userAccount:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:userAccount:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:userAccount:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="toDetail(record)"> <a-icon type="info-circle" />详情 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!-- <vxe-modal
        v-model="uaModalVisible"
        width="70%"
        height="600px"
        title="账务详情"
        :show-zoom="true"
        resize
        :lock-view="false"
        :mask="false"
        position="center"
        @close="handleCloseModal"
        class="vxe-container"
        transfer
      > -->
      <component v-if="reMountedComponent" :is="currentComponent" :ref="refName"></component>
      <!-- </vxe-modal> -->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listUserAccount, delUserAccount, exportUserAccount } from '@/api/recyclable/userAccount'
import CreateForm from './modules/CreateForm'
import { listWorkers } from '@/api/recyclable/users'
import debounce from 'lodash/debounce'
import { listWithdrawalRecord } from '@/api/recyclable/withdrawalRecord'
import { listRecoveryBill } from '@/api/recyclable/recoveryBill'

export default {
  name: 'UserAccount',
  components: {
    CreateForm
  },
  data() {
    this.handleUserNameSearch = debounce(this.handleUserNameSearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: undefined,
        type: undefined,
        accountNo: null,
        amount: null,
        balance: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户账号',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类别',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.typeOptions, record.type)
          }
        },
        {
          title: '账单流水号',
          dataIndex: 'accountNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发生金额',
          dataIndex: 'amount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发生金额后的余额',
          dataIndex: 'balance',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否删除',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: (text, record, index) => {
        //     return this.optionsFormat(this.statusOptions, record.status)
        //   }
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions: [
        { dictLabel: '正常', dictValue: '0' },
        { dictLabel: '删除', dictValue: '1' }
      ],
      typeOptions: [
        { dictLabel: '收入', dictValue: '1' },
        { dictLabel: '提现', dictValue: '2' }
      ],
      componentsMap: {
        recoveryBill: () => import('../recoveryBill/modules/CreateForm.vue'),
        withdrawalRecord: () => import('../withdrawalRecord/modules/CreateForm.vue')
      },
      currentComponent: null,
      uaModalVisible: false,
      userInfo: {},
      // 重载Modal弹窗内组件
      reMountedComponent: true,
      refName: null
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    getListUsers(name) {
      listWorkers({
        userName: name,
        type: '2'
      }).then((response) => {
        this.usersList = response.data
        this.loading = false
      })
    },
    handleUserNameSearch(value) {
      console.log('handleUserNameSearch', value)
      this.loading = true
      this.getListUsers(value)
    },
    handleUserNameChange(value) {
      console.log('handleUserNameChange', value)
      this.form.userId = value
    },
    /** 查询用户账务列表 */
    getList() {
      this.loading = true
      listUserAccount(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        accountNo: undefined,
        amount: undefined,
        balance: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delUserAccount(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportUserAccount(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    async toDetail(record) {
      this.uaModalVisible = true
      this.reMountedComponent = false
      this.$nextTick(() => {
        this.reMountedComponent = true
      })
      this.userInfo = {
        userName: record.userName,
        userId: record.userId,
        accountNo: record.accountNo
      }
      if (record.type === 1) {
        // 收入
        // this.$router.push({
        //   path: '/recyclable/recoveryBill',
        //   query: {
        //     userName: record.userName,
        //     userId: record.userId
        //   }
        // })
        this.refName = 'recoveryBill'
        this.currentComponent = this.componentsMap['recoveryBill']
        listRecoveryBill({
          pageNum: 1,
          pageSize: 10,
          userName: this.userInfo.userName,
          userId: this.userInfo.userId && Number(this.userInfo.userId),
          orderNo: this.userInfo.accountNo
        }).then((response) => {
          this.$refs.recoveryBill.handleUpdate(response.rows[0])
        })
      } else if (record.type === 2) {
        // 提现
        // this.$router.push({
        //   path: '/recyclable/withdrawalRecord',
        //   query: {
        //     userName: record.userName,
        //     userId: record.userId
        //   }
        // })
        this.currentComponent = this.componentsMap['withdrawalRecord']
        this.refName = 'withdrawalRecord'
        listWithdrawalRecord({
          pageNum: 1,
          pageSize: 10,
          userName: this.userInfo.userName,
          userId: this.userInfo.userId && Number(this.userInfo.userId),
          withdrawalNo: this.userInfo.accountNo
        }).then((response) => {
          console.log('refs withdrawalRecord ** ', this.$refs.withdrawalRecord)

          this.$refs.withdrawalRecord.handleUpdate(response.rows[0])
        })
      } else {
        console.error('没有对应的type类型,type=' + record.type + '(' + typeof record.type + ')')
      }
    }
  }
}
</script>
