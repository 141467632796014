/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-06-10 09:53:36
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询物品回收账单列表
export function listRecoveryBill(query) {
  return request({
    url: '/internal/recyclable/recoveryBill/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询物品回收账单详细
export function getRecoveryBill(id) {
  return request({
    url: '/internal/recyclable/recoveryBill/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增物品回收账单
export function addRecoveryBill(data) {
  return request({
    url: '/internal/recyclable/recoveryBill',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改物品回收账单
export function updateRecoveryBill(data) {
  return request({
    url: '/internal/recyclable/recoveryBill',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除物品回收账单
export function delRecoveryBill(id) {
  return request({
    url: '/internal/recyclable/recoveryBill/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出物品回收账单
export function exportRecoveryBill(query) {
  return request({
    url: '/internal/recyclable/recoveryBill/export',
    baseURL,
    responseType: 'blob',
    method: 'get',
    params: query
  })
}

// 资源统计，对列表的数据补充
export function sumResources(query) {
  return request({
    url: '/internal/recyclable/recoveryBill/sumResources',
    baseURL,
    method: 'get',
    params: query
  })
}

// 图片批量下载
export function downlowndPic(query) {
  return request({
    url: '/internal/recyclable/recoveryBill/downlowndPic',
    baseURL,
    method: 'get',
    params: query
  })
}
